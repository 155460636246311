import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import React, { useState } from 'react'
import styled from 'styled-components'
import Lightbox from 'yet-another-react-lightbox'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

interface GalleryDefaultProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;

  @media (min-width: 992px) {
    gap: 1rem;
  }
`

const GridItem = styled.div<{ span: number }>`
  grid-column: span ${(props) => props.span};
  overflow: hidden;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;

  & img {
    object-fit: cover;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  &:hover img {
    transform: scale(1.1);
  }

  @media (max-width: 991.98px) {
    border-radius: 8px;
  }

  @media (min-width: 992px) {
    border-radius: 15px;
  }
`

const GalleryDefault: React.FC<GalleryDefaultProps> = ({ fields }) => {
  const [imageIndex, setImageIndex] = useState(-1)

  let pointer = 0

  return (
    <>
      <section data-component="GalleryDefault">
        <div className="container pb-5">
          <ParseContent className="mb-4" content={fields.description || ''} />
          <Grid>
            {fields.gallery?.map((image, i) => {
              let span = 1

              if (i !== 0) {
                if (pointer % 3 === 0 && pointer % 9) {
                  span = 2
                }
              }

              if (pointer === 9) {
                pointer = 0
              } else {
                pointer++
              }
              return (
                <GridItem
                  span={span}
                  role="button"
                  onClick={() => setImageIndex(i)}
                >
                  <Plaatjie image={image} alt="" className="h-100 w-100" />
                </GridItem>
              )
            })}
          </Grid>
        </div>
      </section>
      <Lightbox
        index={imageIndex}
        open={imageIndex >= 0}
        close={() => setImageIndex(-1)}
        slides={fields.gallery?.map((image) => {
          return {
            src: image?.localFile?.publicURL || '',
          }
        })}
        plugins={[Thumbnails, Slideshow]}
        render={{
          slide: (image, _, rect) => {
            if (!image.height || !image.width) return

            const width = Math.round(
              Math.min(rect.width, (rect.height / image.height) * image.width)
            )
            const height = Math.round(
              Math.min(rect.height, (rect.width / image.width) * image.height)
            )

            return (
              <div style={{ position: 'relative', width, height }}>
                <Plaatjie
                  image={image}
                  loading="eager"
                  alt=""
                  sizes={
                    typeof window !== 'undefined'
                      ? `${Math.ceil((width / window.innerWidth) * 100)}vw`
                      : `${width}px`
                  }
                />
              </div>
            )
          },
        }}
      />
    </>
  )
}

export default GalleryDefault
